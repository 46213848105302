@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@layer utilities {
  .word-spacing-0 {
    word-spacing: 0em;
  }

  .word-spacing-sm {
    word-spacing: 0.1em;
  }

  .word-spacing {
    word-spacing: 0.15em;
  }
}

body {
  margin: 0;
  font-family: 'DM Sans', 'Helvetica Neue', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #707581;
}

.mesh-gradient-8 {
  background: url('/public/images/mesh-gradient-8.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.mesh-gradient-5 {
  background: url('/public/images/mesh-gradient-5.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.mesh-gradient-5-sm {
  background: url('/public/images/mesh-gradient-5-sm.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.mesh-gradient-4 {
  background: url('/public/images/mesh-gradient-4.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.mesh-gradient-9 {
  background: url('/public/images/mesh-gradient-9.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.gradient {
  background: rgb(7,5,26);
  background: linear-gradient(180deg, rgba(7,5,26,1) 0%, rgba(35,28,105,1) 100%);
}

.gradient-darker {
  background: rgb(7,5,26);
  background: linear-gradient(180deg, rgba(7,5,26,1) 0%, rgba(16,11,69,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 1640px) and (min-width: 1535px) {
  .container {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

@media screen and (max-width: 1350px) and (min-width: 1280px) {
  .container {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

@media screen and (max-width: 1080px) and (min-width: 1024px) {
  .container {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

@media screen and (max-width: 785px) and (min-width: 767px) {
  .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

/* FlyoutMenu */
#flyoutMenu {
  width: 100vw;
  height: calc(100vh - 80px);
  position: absolute;
  top: 80px;
  left: 0;
  /* transform: translate3d(-100vw, 0, 0); */
  transform: translate3d(-100vw, 0, 0);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  overflow: scroll;
  z-index: 300;
}

#flyoutMenu.show {
  transform: translate3d(0vw, 0, 0);
}

/* Hamburger icon */
.menu-btn {
  transition: all 0.5s ease-in-out;
  width: 35px;
  height: 80px;
}

.menu-btn__burger {
  width: 30px;
  height: 4px;
  background: #2c1c66;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 4px;
  background: #2c1c66;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.menu-btn__burger::before {
  transform: translateY(-10px);
}

.menu-btn__burger::after {
  transform: translateY(10px);
}

/* Menu Btn Animation */

.menu-btn.open .menu-btn__burger {
  /*     transform: translateX(-50px); */
  background: transparent;
}

.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg);
}
.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg);
}

/* Desktop dropdown menu */
.dropdown-menu {
  opacity: 0;
  transform: translateY(-10px);
  transition: 150ms opacity ease-in-out, transform 150ms ease-in-out;
  pointer-events: none;
}

.active-link {
  font-weight: bold;
}

.nav-focus:hover > .dropdown-menu{
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}


/* Mobile Menu */
  /* Arrow Animation Styles Start */
    .show-hide-item,
    .arrow,
    .arrow:before,
    .arrow:after,
    .show-hide-content {
      transition: all .5s ease-in-out;
    }

    .arrow {
      position: absolute;
      right: 30px;
      top: 42px;
    }

    .arrow,
    .arrow:before,
    .arrow:after {
      border-radius: 25px;
    }

    .arrow:before {
      content: " ";
      width: 15px;
      height: 3px;
      left: 50%;
      margin-left: -10px;
      background-color: #6438ff;
      position: absolute;
      transform: rotate(45deg);
    }

    .arrow:after {
      content: " ";
      width: 15px;
      height: 3px;
      background-color: #6438ff;
      position: absolute;
      right: -15px;
      transform: rotate(-45deg);
    }

    .open .arrow:before {
      transform: rotate(-45deg);
    }

    .open .arrow:after {
      transform: rotate(45deg);
    }
  /* Arrow Animation Styles End */

.show-hide-item {
  color: #2c1c66;
  display: block;
  cursor: pointer;
  position: relative;
  padding: 20px;
  overflow: hidden;
  min-height: 80px;
}

.show-hide-item.open {
  cursor: default;
  transition: all .3s ease-in-out;
}

.show-hide-content {
  color: black;
  font-size: 1rem;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.open .show-hide-content {
  opacity: 1;
  transition: all .3s ease-in-out;
}

.c-graph-tip, .c-graph-tip__header { padding:.1875rem .3125rem;color:inherit}
.c-graph-tip{font-kerning:normal;-webkit-font-kerning:normal;text-rendering:optimizeLegibility;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;max-width:9.375rem;min-width:4.375rem;position:relative;margin-left:-.3125rem;border:.0625rem solid currentColor;box-shadow:0 0 .125rem rgba(21,59,73,.2);border-radius:.1875rem;font-size:.625rem;background-color:#fff}
.c-graph-tip:after{content:"";display:block;background-color:rgba(0,0,0,.2);position:absolute;top:.125rem;right:.0625rem;bottom:.0625rem;left:-.0625rem;z-index:-1;box-shadow:.0625rem .0625rem 1.25rem rgba(0,0,0,.2),.1875rem .3125rem .625rem rgba(0,0,0,.2)}
.c-graph-tip__header{background-color:currentColor;font-family:"Roboto Condensed",sans-serif;font-weight:700;line-height:1.25;text-transform:uppercase;letter-spacing:-.02em;font-size:.75rem;margin:-.1875rem -.3125rem .25rem}
.c-graph-tip__header>span{display:block;line-height:1.1;color:#fff;text-shadow:.0625rem .0625rem .3125rem rgba(0,0,0,.2)}.c-graph-tip>span{color:#31373e;display:block}
.c-graph-tip>span+span, .c-graph-tip > span+ul {margin-top:.125rem}
.c-graph-tip__list,.c-graph-tip__list>li{color:#31373e}